@import url("https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/hk-grotesk.min.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply m-0 bg-background text-secoset-green-900;
  font-family: "HK Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  @apply flex items-center justify-center w-screen h-screen;
}

@layer components {
  .table-header-title {
    @apply text-base whitespace-nowrap font-bold text-left px-2;
  }

  .input {
    @apply border border-slate-400 rounded text-sm text-secoset-green-900 px-4 bg-white py-3 w-full transition ease-linear duration-150 hover:border-slate-900;
  }

  .blue-button {
    @apply bg-secoset-green-700 border border-secoset-green-700 text-white font-semibold hover:bg-white hover:text-secoset-green-700 rounded-sm text-center px-4 py-2 transition ease-linear duration-150 disabled:opacity-40 disabled:cursor-default disabled:hover:bg-secoset-green-700;
  }
  .empty-button {
    @apply border border-secoset-green-700 hover:bg-slate-50 text-secoset-green-700 font-semibold text-center rounded-sm px-4 py-2 transition ease-linear duration-150 disabled:opacity-40 disabled:cursor-default disabled:hover:bg-transparent;
  }
  .red-button {
    @apply border border-red-700 hover:bg-slate-50 text-red-700 font-semibold text-center rounded-sm px-4 py-2 transition ease-linear duration-150 disabled:opacity-40 disabled:cursor-default disabled:hover:bg-transparent;
  }
}
